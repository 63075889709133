import React, {useState} from "react";
import "./EthereumWallet.css";
import {
    checkWalletAvailable,
    getUserAddress,
} from "./Web3Actions";
import {gerUserTokenBalance} from "./SmartActions";
import Footer from "../Footer";
import Nav from "../Nav";

const WalletUI = () => {

    const [data, setData] = useState({
        Eth_Address: "Unavailable",
        baby_punks_token_balance: "0",
    });
    const [connect, setConnect] = useState(false);
    const [solAddress, setSolAddress] = useState(false);
    const [showMSG, setShowMSG] = useState('Please Connect Your Wallet First');

    const connectButton = async () => {
        let wallet = await checkWalletAvailable();
        let address = await getUserAddress();


        console.log(address);
        if (address !== undefined) {
            let address = await getUserAddress();
            let tokenBalance = await gerUserTokenBalance(address);
            console.log(address);
            setConnect(wallet);
            setData({
                Eth_Address: address,
                baby_punks_token_balance: tokenBalance,
            });
        } else if (address === undefined) {
            setShowMSG("Your Request Is Expierd Please Refresh The Page")
            console.log("rejected");

        }


    };


    const getSolanaAddress = (event) => setSolAddress(event.target.value)


    return (
        <>
            <Nav wallet={false} showBtn={false}/>
            <div className="main-content-wrapper backgroundGradient root_container">
                <div className="wallet_container">
                    <h3 className="header_Etharium_wallet">Ethereum Wallet</h3>

                    {(!connect) ? <button className="btn_connect" disabled={false} onClick={async () => {
                        await connectButton();
                    }}><span>Connect</span></button> : null}

                    <div className="info_root_section">
                        {(connect) ? <div className="info_head_section">
                            <p><span style={{color: "black"}}> Eth-Address</span> <br></br> {data.Eth_Address}</p>
                            <p><span style={{color: "black"}}>BabyPunks</span> <br></br> {data.baby_punks_token_balance}
                            </p>

                            <input className="input_address" type="text" placeholder="Enter The Solana Wallet Address"
                                   onChange={getSolanaAddress}/>

                            <br></br>

                            <button disabled className="btn_connect" onClick={() => {

                                //object are here 

                                const obj = {
                                    Eth_Address: data.Eth_Address,
                                    baby_punks_token_balance: data.baby_punks_token_balance,
                                    sol_Address: solAddress,
                                }

                                console.log(obj);

                            }}><span>Get Airdrop (Soon)</span></button>

                        </div> : <p style={{margin: "2vh"}}>{showMSG}</p>}


                    </div>


                </div>

            </div>
            <section className="backgroundGradient">
                <Footer/>
            </section>
        </>
    )
}

export default WalletUI;