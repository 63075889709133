import web3 from "../../web3";

//Check wallet available
export const checkWalletAvailable = () => {
  if (typeof window.ethereum !== "undefined") {
    if (window.ethereum && window.ethereum.isMetaMask) {
      return true;
    } else {
      return false;
    }
  } else {
    window.open("https://metamask.io/", "_blank");
    alert("Please Download Metamask ? If You Download And Set Up Your wallet then Refresh Page Again");
    return false;
  }
};

//Check correct network Id
export const checkCorrectNetwork = async () => {
  if (web3) {
    let chainID;
    chainID = await web3.eth.getChainId();
    return chainID;
  }
};

//Get User Address from Web3
export const getUserAddress = async () => {
  if (web3) {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      }).catch((err) => console.log(err)); ///it pop up the metamask

      const accountAddress = accounts[0]; //select by thge user if they have multi plate account
      return accountAddress;
    } catch (err) {
      console.log(err);
    }
  }
};

// GET Main Token Balance
export const getMainBalance = async (address) => {

  if (web3) {
    if (address) {
      let balance = await web3.eth.getBalance(address).catch((err) => console.log(err));
      let mainBalance = await web3.utils.fromWei(balance);
      return mainBalance;
    } else {
      console.log(address);
    }


  }


};

