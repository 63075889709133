import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  WalletMultiButton,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-material-ui";
import { HashLink as Link } from 'react-router-hash-link';

import logo from "../assets/img/logo-primary.png";
import pdf1 from "../assets/pdf/WP3.pdf";
import pdf2 from "../assets/pdf/Audit.pdf";

const Nav = ({ wallet, showBtn }) => {
  const history = useHistory();
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <div className={colorChange ? "header colorChange" : "header"}>
      <div className="container">
        <div className="header-content-wrapper">
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
              <a
                href="/"
                className="site-logo navbar-brand"
                title="back to index"
              >
                <img
                  loading="lazy"
                  width="200"
                  src={logo}
                  alt="babypunks"
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarNavDropdown"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      NFT
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <Link
                          title="Mint"
                          className="dropdown-item"
                          to="/#MINT"
                        >
                          Mint
                        </Link>
                      </li>
                      <li>
                        <Link
                          title="story"
                          className="dropdown-item"
                          to="/#story"
                        >
                          Story
                        </Link>
                      </li>
                      <li>
                        <Link
                          title="roadmap"
                          className="dropdown-item"
                          to="/#roadmap"
                        >
                          Roadmap
                        </Link>
                      </li>
                      <li>
                        <Link
                          title="team"
                          className="dropdown-item"
                          to="/#team"
                        >
                          Team
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Buy
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="/#"
                          role="button"
                          aria-expanded="false"
                        >
                          Coming soon to Solana
                        </a>
                      </li>

                    </ul>

                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      BabyPunks Coin
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a
                          title="White paper"
                          className="dropdown-item"
                          href={pdf1}
                          download="WP3.pdf"
                        >
                          White paper
                        </a>
                      </li>
                      <li>
                        <a
                          title="Audit"
                          className="dropdown-item"
                          href={pdf2}
                          download="Audit.pdf"
                        >
                          Audit
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Social Media
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a
                          aria-label="Twitter"
                          className="sc-1i4jqyq-0 dgglXh sc-1gbjr5k-6 jemGW dropdown-item"
                          href="https://twitter.com/BabyPunksCoin"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Twitter
                        </a>
                      </li>
                      <li>
                        <a
                          aria-label="Telegram"
                          className="sc-1i4jqyq-0 dgglXh sc-1gbjr5k-6 jemGW dropdown-item"
                          href="https://t.me/babypunksofficial"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Telegram
                        </a>
                      </li>
                      <li>
                        <a
                          aria-label="Instagram"
                          className="sc-1i4jqyq-0 dgglXh sc-1gbjr5k-6 jemGW dropdown-item"
                          href="https://www.instagram.com/babypunkscoin"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Instagram
                        </a>
                      </li>
                      <li>
                        <a
                          aria-label="Facebook"
                          className="sc-1i4jqyq-0 dgglXh sc-1gbjr5k-6 jemGW dropdown-item"
                          href="https://www.facebook.com/BabyPunksCoin"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                          aria-label="Reddit"
                          className="sc-1i4jqyq-0 dgglXh sc-1gbjr5k-6 jemGW dropdown-item"
                          href="https://www.reddit.com/r/babypunksofficial"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Reddit
                        </a>
                      </li>
                      <li>
                        <a
                          aria-label="Discord"
                          className="sc-1i4jqyq-0 dgglXh sc-1gbjr5k-6 jemGW dropdown-item"
                          href="http://discord.gg/babypunks"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Discord
                        </a>
                      </li>
                      <li>
                        <a
                          aria-label="Medium"
                          className="sc-1i4jqyq-0 dgglXh sc-1gbjr5k-6 jemGW dropdown-item"
                          href="https://medium.com/@BabyPunks"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Medium
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="/#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Contact
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink">
                      <li>
                        <a href="mailto:info@babypunks.com"
                          aria-label="Contact"
                          className="dropdown-item"
                        >
                          info@babypunks.com
                        </a>
                      </li>
                    </ul>
                  </li>
                  {showBtn &&
                    <>
                      <li className="nav-item">
                        {!wallet &&
                          <WalletMultiButton id="connectButton"></WalletMultiButton>}
                        {wallet && <WalletDisconnectButton />}
                      </li>
                      <li className="nav-item">
                        <button style={{ margin: "1vh" }} className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary" onClick={() => history.push("/balance")}><span>Airdrop</span></button>
                      </li>
                    </>
                  }
                </ul>
              </div>
            </div>
          </nav>
        </div>

        {/* <Modal onClose={() => setShowModal(false)} show={showModal} /> */}
      </div>
    </div>
  );
};

export default Nav;
