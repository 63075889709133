import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { CircularProgress, Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import * as anchor from "@project-serum/anchor";
import {LAMPORTS_PER_SOL} from "@solana/web3.js";
import {useAnchorWallet} from "@solana/wallet-adapter-react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import {
    CandyMachine,
    awaitTransactionSignatureConfirmation,
    getCandyMachineState,
    mintOneToken,
} from "./candy-machine";

import Nav from "./components/Nav";
import Mint from "./components/Mint";
import Story from "./components/Story";
import Roadmap from "./components/Roadmap";
import Team from "./components/Team";
import Footer from "./components/Footer";

import pixelBtn from "./assets/img/pixel_button.png";

const MintContainer = styled.div``;

export interface HomeProps {
    candyMachineId: anchor.web3.PublicKey;
    config: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    treasury: anchor.web3.PublicKey;
    txTimeout: number;
}

let subtitle: any;

const Home = (props: HomeProps) => {
    const [balance, setBalance] = useState<number>();
    // const [isActive, setIsActive] = useState(false); // true when countdown completes
    const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
    const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
    const [mintNumber, setMintNumber] = useState(1);
    const [mintedNumber, setMintedNumber] = useState(1);
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    let wallet = useAnchorWallet();


    const [itemsAvailable, setItemsAvailable] = useState(0);
    const [itemsRedeemed, setItemsRedeemed] = useState(0);
    const [itemsRemaining, setItemsRemaining] = useState(0);


    const refreshCandyMachineState = () => {
        (async () => {
            if (!wallet) return;

            const {
                candyMachine,
                itemsAvailable,
                itemsRemaining,
                itemsRedeemed,
            } = await getCandyMachineState(
                wallet as anchor.Wallet,
                props.candyMachineId,
                props.connection
            );

            setItemsAvailable(itemsAvailable);
            setItemsRemaining(itemsRemaining);
            setItemsRedeemed(itemsRedeemed);


            setIsSoldOut(itemsRemaining === 0);
            setCandyMachine(candyMachine);
        })();
    };

    useEffect(refreshCandyMachineState, [
        wallet,
        props.candyMachineId,
        props.connection,
    ]);

    const [candyMachine, setCandyMachine] = useState<CandyMachine>();

    const connectButtonClick = async () => {
        const b = document.getElementById("connectButton");
        b?.click();
    };

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = "#f00";
    }

    function closeModal() {
        setIsOpen(false);
    }

    const onMint = async () => {
        for (let index = 0; index < mintNumber; index++) {
            setMintedNumber(index + 1);
            try {
                setIsMinting(true);
                if (wallet && candyMachine?.program) {

                    const mintTxId = await mintOneToken(
                        candyMachine,
                        props.config,
                        wallet.publicKey,
                        props.treasury,
                        1
                    );

                    const status = await awaitTransactionSignatureConfirmation(
                        mintTxId,
                        props.txTimeout,
                        props.connection,
                        "singleGossip",
                        false
                    );

                    if (!status?.err) {
                        setAlertState({
                            open: true,
                            message: "Congratulations! Mint succeeded!",
                            severity: "success",
                        });

                        refreshCandyMachineState();
                    } else {
                        setAlertState({
                            open: true,
                            message: "Mint failed!! Please try again!",
                            severity: "error",
                        });
                    }
                }
            } catch (error: any) {


                // TODO: blech:
                let message = error.message || "Minting failed! Please try again!";
                if (!error.msg) {
                    if (error.message.indexOf("0x138")) {
                    } else if (error.message.indexOf("0x137")) {
                        message = `SOLD OUT!`;
                    } else if (error.message.indexOf("0x135")) {
                        message = `Insufficient funds to mint. Please fund your wallet.`;
                    }
                } else {
                    if (error.code === 311) {
                        message = `SOLD OUT!`;
                        setIsSoldOut(true);
                    } else if (error.code === 312) {
                        message = `Minting period hasn't started yet.`;
                    }
                }

                setAlertState({
                    open: true,
                    message,
                    severity: "error",
                });
            } finally {
                if (wallet) {
                    const balance = await props.connection.getBalance(wallet.publicKey);
                    setBalance(balance / LAMPORTS_PER_SOL);
                }
                setIsMinting(false);
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (wallet) {
                const balance = await props.connection.getBalance(wallet.publicKey);
                setBalance(balance / LAMPORTS_PER_SOL);
            }
        })();
    }, [wallet, props.connection]);

    useEffect(() => {
        (async () => {
            if (!wallet) return;

            const {candyMachine, itemsRemaining} =
                await getCandyMachineState(
                    wallet as anchor.Wallet,
                    props.candyMachineId,
                    props.connection
                );

            setIsSoldOut(itemsRemaining === 0);
            setCandyMachine(candyMachine);
        })();
    }, [wallet, props.candyMachineId, props.connection]);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        refreshCandyMachineState();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <main>
            <div>
                <Dialog className="text-center" open={open} onClose={handleClose} PaperProps={{
                    style: {
                        backgroundColor: '#b84185',
                    },
                }}>
                    <DialogTitle>Mint Ramdom NFT</DialogTitle>
                    <DialogContent>

                        {wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>}

                        {wallet && <p>Minted: {itemsRedeemed} / {itemsAvailable}</p>}

                        {wallet && <p>Remaining: {itemsRemaining}</p>}

                        <img
                            id="ramdomBaby"
                            loading="lazy"
                            src="https://s3.eu-central-1.wasabisys.com/steleros/gif_2.gif"
                            style={{borderRadius: "16px", width: "350px", height: "350px"}}
                            alt="header"
                        />

                        <TextField
                            autoFocus
                            margin="dense"
                            id="mintamount"
                            label="Mint amount"
                            type="number"
                            InputProps={{
                                inputProps: {
                                    max: 20,
                                    min: 1,
                                },
                            }}
                            fullWidth
                            variant="standard"
                            value={mintNumber}

                            onChange={(e) => {
                                let value = Number(e.target.value)
                                if(value === 0) value++
                                setMintNumber(value);
                            } }
                        />
                        <span style={{fontSize: "8px", color: "white"}}>NOTE: Enable auto approve for mint more than one NFT at same time</span>

                        <div style={{marginTop: "0.5rem"}}>
                            {isMinting ? (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignContent: "center",
                                    justifyContent: "center"
                                }}>
                                    <div>
                                        <span style={{fontSize: "12px"}}>Minting {mintedNumber} of {mintNumber}</span>
                                    </div>
                                    <div>
                                        <CircularProgress/>
                                    </div>
                                </div>
                            ) : (
                                <img
                                    onClick={wallet ? onMint : connectButtonClick}
                                    className="randomImage"
                                    loading="lazy"
                                    id="random"
                                    style={{cursor: "pointer", width: "179px", height: "77px"}}
                                    src={pixelBtn}
                                    alt="random nft"
                                />
                            )}
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            <MintContainer id="mintContainer">
                <>
                    <Nav wallet={wallet} showBtn={true} />
                    <div className="main-content-wrapper backgroundGradient">
                        <section className="main-section medium-padding120 responsive-align-center">
                            <Mint handleClickOpen={handleClickOpen} />
                        </section>

                        <section>
                            <Story />
                        </section>
                        <br/>
                        <br/>
                        <br/>
                        <section id="roadmap">
                            <Roadmap />
                        </section>
                        <br/>
                        <br/>
                        <br/>
                        <section>
                            <Team />
                        </section>
                    </div>
                    <section className="backgroundGradient">
                        <Footer />
                    </section>
                </>
            </MintContainer>

            <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({...alertState, open: false})}
            >
                <Alert
                    onClose={() => setAlertState({...alertState, open: false})}
                    severity={alertState.severity}
                >
                    {alertState.message}
                </Alert>
            </Snackbar>
        </main>
    );
};

interface AlertState {
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error" | undefined;
}

export default Home;
