import React from "react";

const Roadmap = () => {

   return (
      <>
         <div className="container text-center">
            <div className="col-md-12 col-sm-12 col-xs-12 mb-4">
               <h1>Roadmap</h1>
            </div>
         </div>
         <div className="container">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-lg-6">
                     <div className="road-content">
                        <div className="red-process20"></div>
                        <div className="road-detail">
                           <h4>20% Sold</h4>
                           <h6 className="red">NFT Giveaway</h6>
                           <p>
                              A random airdrop of 20 NFTs to early community
                              members in our discord.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-lg-6">
                     <div className="road-content">
                        <div className="red-process40"></div>
                        <div className="road-detail">
                           <h4>40% Sold</h4>
                           <h6 className="red">BabyPunks 10x Baby Bonus</h6>
                           <p>
                              On the 4000th sale, there will be a payout 10x the
                              mint price in SOL to 4 community members in our
                              discord.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-lg-6">
                     <div className="road-content">
                        <div className="red-process50"></div>
                        <div className="road-detail">
                           <h4>50% Sold</h4>
                           <h6 className="red">BabyPunks Merch</h6>
                           <p>
                              BabyPunks Merch store will be rolled out where NFT
                              holders will get first dibs and exclusive access to
                              limited edition t-shirts, hats, and hoodies.Non-NFT
                              holders will be given access at a later date.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-lg-6">
                     <div className="road-content">
                        <div className="red-process60"></div>
                        <div className="road-detail">
                           <h4>60% Sold</h4>
                           <h6 className="red">BabyPunks Sweeper</h6>
                           <p>
                              A liquidity wallet will be created that will be used
                              to buy the floor of BabyPunk NFTs.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-lg-6">
                     <div className="road-content">
                        <div className="red-process70"></div>
                        <div className="road-detail">
                           <h4>70% Sold</h4>
                           <h6 className="red">BabyPunks Gives Back</h6>
                           <p>
                              The community will direct a $10,000 gift to a
                              charity of the community's choosing. A grant of
                              $15,000 will be set up to fund a community project
                              that contributes to the growth of BabyPunks.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-lg-6">
                     <div className="road-content">
                        <div className="red-process80"></div>
                        <div className="road-detail">
                           <h4>80% Sold</h4>
                           <h6 className="red">TeenPunks</h6>
                           <p>
                              We will create a limited collection of stylized
                              TeenPunks and airdrop them to all holders.
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="col-12 col-lg-6">
                     <div className="road-content">
                        <div className="red-process100"></div>
                        <div className="road-detail">
                           <h4>100% Sold</h4>
                           <h6 className="red">The PUNK-A-TRON Arcade</h6>
                           <p>
                              We will build an even bigger arcade than we planned
                              for previously that will host approximately 10
                              titles by the end of this year.The games will
                              feature retro old school classics.All NFT holders
                              will be able to stake their NFTs to earn BabyPunk
                              Tokens from transaction fees paid by players to play
                              the games to compete head to head for prizes such
                              popular NFTs, and SOL tokens.
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Roadmap;