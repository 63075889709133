import React from "react";
import banner2 from "../assets/img/banner2.png";

const Story = () => {

   return (
      <div id="story" className="container text-center">
         <div className="col-md-12 col-sm-12 col-xs-12 mb-2">
            <h1>The Story</h1>
         </div>
         <div className="row text-center" id="story" style={{ justifyContent: "center" }}>
            <div className="row mb-5">
               <img loading="lazy" src={banner2} alt="storyImage" />
            </div>
            <div className="col-md-12 col-sm-12 col-xs-12 transparent">
               <p>
                  BabyPunks are the spawn of what happens when you have a
                  bunch of badass CryptoPunks mixed with pop culture,
                  internet memes and peyote.This lot are looking for a new
                  home and are up for adoption.They were originally going to
                  find new homes on Ethereum, but it was too old, congested
                  and full of other babies who looked neglected.The
                  BabyPunks banded together and set a course for uncharted
                  territory - Solana.It’s every pixel's dream and now they
                  have their chance at the big leagues.
               </p>
               <p>
                  All they need is the right person to take them under their
                  wing and teach them the ropes. These punks may be small
                  but they have big dreams and their properties uniquely
                  reflect who they are and promise to reward their owners
                  handsomely.
               </p>
            </div>
         </div>
      </div>
   );
};

export default Story;