import React from "react";

const Footer = () => {
   const year = new Date().getFullYear();

   return (
      <div id="footer" className="container text-center backgroundGradient">
         <div className="row">
            <div className="col-lg-12 col-sm-12 col-xs-12 mb-5">
               <h3>BabyPunks</h3>
               <h5>© All right reserved {year}.</h5>
            </div>
         </div>
      </div>
   );
};

export default Footer;