import Web3 from "web3";

var web3;

if (typeof window.web3 !== "undefined") {
    web3 = new Web3(window.web3.currentProvider);

} else {
    // web3 = new Web3(
    //     new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/e21d24d1fc56406392564e6fabdd368c")
    // );
    web3 = false;
}
export default web3;

export const openMetamask = () => {
    if (typeof window.web3 !== "undefined") {
        web3 = new Web3(window.web3.currentProvider);

    } else {
        web3 = false;
    }
}


