import React from "react";

import baby3 from "../assets/img/baby_3.png";
import baby5 from "../assets/img/baby_5.png";
import baby7 from "../assets/img/baby_7.png";
import baby8 from "../assets/img/baby_8.png";
import baby9 from "../assets/img/baby_9.png";
import baby13 from "../assets/img/baby_13.png";
import baby14 from "../assets/img/random/21.png";
import baby15 from "../assets/img/random/14.png";

const Team = () => {
  return (
    <div id="team" className="container text-center">
      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12 mb-5">
          <h1>The Team</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <img src={baby3} alt="dev" />
          <p>BabyPunkGuy</p>
          <p>Dev</p>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <img src={baby15} alt="smmark" />
          <p>Nefu</p>
          <p>Social Media Marketing</p>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <img src={baby7} alt="man" />
          <p>SK</p>
          <p>Project Management</p>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <img src={baby8} alt="marks" />
          <p>CaptainM00n</p>
          <p>Marketing Strategy</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <img src={baby9} alt="marks" />
          <p>Mr.Óseo</p>
          <p>NFT Designer</p>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <img src={baby13} alt="marks" />
          <p>NKT43</p>
          <p>Dev</p>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <img src={baby14} alt="marks" />
          <p>DRG809</p>
          <p>Dev</p>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <img src={baby5} alt="marks" />
          <p>Lucky Punk</p>
          <p>Dev</p>
        </div>
      </div>
    </div>
  );
};

export default Team;
