import React from "react";

import CountDownTimer from "./CountDownTimer";
import babyIlustration from "../assets/img/baby_illustration.png";
import pixelBtn from "../assets/img/pixel_button.png";


const Mint = ({handleClickOpen}) => {

   const distance =
      new Date(Date.UTC(2021, 9, 8, 21)).getTime() - new Date(Date.now()).getTime();
   const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
   const hoursMinSecs = {
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: hours,
      minutes: Math.floor((distance / 1000 / 60) % 60),
      seconds: Math.floor((distance / 1000) % 60),
   };
   console.log(distance);
   return (
      <div className="container">
         <div className="row" style={{ justifyContent: "center" }}>
            <div
               className="col-lg-6 col-md-6 col-lg-offset-0 col-sm-6 col-xs-6 ihdgua-0 bDorMw"
               style={{ textAlign: "center" }}
            >
            </div>
            <div className="row" id="MINT">
               <div className="col-lg-7 col-md-12 col-lg-offset-0 col-sm-12 col-xs-12">
                  <div
                     style={{ marginBottom: "130px", textAlign: "center" }}
                  >
                     <h3 style={{ marginTop: "1rem", textAlign: "center" }}>
                        10,000 unique BabyPunks live on Solana
                     </h3>

                     <p>
                        BabyPunks is a limited NFT collection on the Solana
                        blockchain.Supply is capped at 10,000.Your BabyPunks
                        allow you to earn 5% royalties paid in SOL tokens from
                        every buy & sell for life.
                     </p>

                     <p>
                        All BabyPunks are programmatically generated to
                        include numerous traits and rarity.Adopting a BabyPunk
                        also gives you access to features within our BabyPunk
                        Arcade which will feature a series of old school
                        classic games similar to Tetris, Flappy Bird, and
                        more.
                     </p>

                     <p>
                        All BabyPunks will be revealed shortly after being
                        minted along with activating special community
                        features based on the roadmap.
                     </p>
                  </div>
               </div>
               <div
                  className="col-lg-5 col-md-12 col-lg-offset-0 col-sm-12 col-xs-12 text-center">
                  <div className="widget w-distribution-ends">
                     <img
                        className="randomImage"
                        loading="lazy"
                        id="random"
                        src="https://s3.eu-central-1.wasabisys.com/steleros/gif_1.gif"
                        style={{ borderRadius: "16px", width: "400px", height: "400px" }}
                        alt="random nft"
                     />
                     <img
                        onClick={handleClickOpen}
                        className="randomImage mt-1"
                        loading="lazy"
                        id="random"
                        style={{ cursor: "pointer" }}
                        src={pixelBtn}
                        alt="random nft"
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-lg-5 col-md-12 col-lg-offset-0 col-sm-12 col-xs-12">
               <div className="widget w-distribution-ends">
                  <img
                     loading="lazy"
                     id="random2"
                     src={babyIlustration}
                     alt="babypunk ilustration"
                  />
               </div>
            </div>
            <div className="col-lg-7 col-md-12 col-lg-offset-0 col-sm-12 col-xs-12">
               <div style={{ marginBottom: "130px", textAlign: "center" }}>
                  <h4>
                     These pixel babies earn you passive income for life.
                     Profits go to the community.
                  </h4>

                  <p style={{ fontSize: "1.6rem", marginTop: "4rem" }}>
                     Minting now
                  </p>

                  <p style={{ fontSize: "1.7rem" }}>Price: 0.5 Solana</p>
                  {distance > 1 && (
                     <CountDownTimer hoursMinSecs={hoursMinSecs} />
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default Mint;