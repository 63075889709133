import pwrContract from "./pwarConnection";
import web3 from "../../web3";

export const gerUserTokenBalance = async (address) => {

  if (web3) {
    let response = await pwrContract.methods.balanceOf(address).call();
    let weiBalance = web3.utils.fromWei(response);
    return weiBalance;
  }
};
